import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { SidebarTabs } from './SidebarTabs/SidebarTabs';
import { SidebarFilter } from './SidebarFilter/SidebarFilter';
import { ListUsers } from './ListUsers/ListUsers';
import { Language } from './Language/Language';
import { Filter } from './Filter/Filter';
import { UnfoldSvg } from 'assets/svg-icon';
import { IUsers, IUsersRequest } from 'redux/reducers/support/types';
import { useSelector, useDispatch } from 'react-redux';
import { getTypesSelector } from 'redux/reducers/support/selectors';
import { chatOpen } from 'redux/reducers/app/reducer';
import { getCountriesRequest } from 'redux/reducers/utils/reducer';
import { getCountries } from 'redux/reducers/utils/selectors';
import { getUsersRequest } from 'redux/reducers/support/reducer';

interface IProps {
	onOpenUser: (value: number, status: number, user_id: number) => void;
	activUser: number;
	hideLeft: boolean;
	setHideLeft: (value: boolean) => void;
	users: null | IUsers;
	onFilter: (values: IUsersRequest) => void;
	onSaveChange: () => void;
	offset: number;
	setOffset: (value: (prev: number) => number) => void;
	scrollRef: any;
}

export const SidebarSupportLeft: FC<IProps> = ({
	setHideLeft,
	hideLeft,
	onOpenUser,
	activUser,
	users,
	onFilter,
	onSaveChange,
	offset,
	setOffset,
	scrollRef,
}) => {
	const dispatch = useDispatch();
	const [checkBtn, setCheckBtn] = useState<number | null>(null);
	const [nameBtn, setNameBtn] = useState<string>('All');
	const [content, setContent] = useState<string>('');
	const types = useSelector(getTypesSelector);
	const countries = useSelector(getCountries);
	const [country, setCountry] = useState<string>('');
	const [status, setStatus] = useState<{ status: null | number }>({ status: null });

	const onCheckBtn = (value: number | null, name: string) => {
		onFilter({ status: value });
		setStatus({ status: value });
		setCheckBtn(value);
		setNameBtn(name);
		chatOpen(value);
	};

	const forFilter = (value: string) => {
		setContent(value === content ? '' : value);
	};

	const contentReset = () => {
		setContent('');
	};

	const byCountry = (value: string, code: string) => {
		setCountry((prev) => (prev === value ? '' : value));
		onFilter({
			country_code: country === value ? '' : code,
			country_name: country === value ? '' : value,
		});
	};

	useEffect(() => {
		dispatch(getCountriesRequest());
	}, []);

	useEffect(() => {
		dispatch(getUsersRequest(status));
	}, [status]);

	const changeContentSidebar = () => {
		switch (content) {
			case 'country':
				return (
					<Language
						onSaveChange={onSaveChange}
						byCountry={byCountry}
						country={country}
						countries={countries}
						contentReset={contentReset}
					/>
				);
			case 'filter':
				return (
					<Filter
						forFilter={forFilter}
						onSaveChange={onSaveChange}
						onFilter={onFilter}
						types={types}
					/>
				);

			default:
				return (
					<ListUsers
						onOpenUser={onOpenUser}
						activUser={activUser}
						users={users}
						types={types}
						setOffset={setOffset}
						offset={offset}
						scrollRef={scrollRef}
					/>
				);
		}
	};

	return (
		<div
			className={classNames('sidebar-left', {
				'sidebar-left-width': hideLeft,
			})}
		>
			{hideLeft ? (
				<div onClick={() => setHideLeft(false)} className="pointer sidebar-left__btn">
					<UnfoldSvg />
					<span className="sidebar-left__request">Request</span>
				</div>
			) : (
				<>
					<SidebarTabs checkBtn={checkBtn} onCheckBtn={onCheckBtn} />
					<SidebarFilter forFilter={forFilter} onFilter={onFilter} />

					<div className="sidebar-left__info">
						<span className="sidebar-left__text-gray">{nameBtn}</span>
						<span className="sidebar-left__number">{users?.total || 0}</span>
					</div>

					{changeContentSidebar()}
				</>
			)}
		</div>
	);
};
