import { NotVerifiedGraySvg } from 'assets/svg-icon';
import { IOpenPopup } from 'components/ChannelsComponents/ChannelsBodyTable';
import { Block } from 'components/Popups/Block';
import moment from 'moment';
import { FC, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { putUpgradeRequest } from 'redux/reducers/users/reducer';
import { IUsers } from 'redux/reducers/users/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsersDetails } from 'services/constants/lists-table';
import { EChannelUpgradeType } from 'services/constants/type';
import { NotFound } from 'ui/NotFound/NotFound';
import { SvgUnverifiedAccount, SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';
import Highlighter from 'react-highlight-words';
import { getAllCountries } from 'redux/reducers/users/selectors';
import { findCountry } from 'untils/findCountry';
import { IMembers } from 'redux/reducers/channels/types';
import { numeralFunc } from 'untils/numerals';
import { Avatar } from 'ui/Avatar/Avatar';
import { NUMDEF } from 'CONST/cardName';

interface IPropsUsersTableBody {
	members: Array<IMembers | IUsers>;
	param: string | undefined;
	search?: string;
}

export const UsersTableBody: FC<IPropsUsersTableBody> = ({ search = '', members, param }) => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);
	const dispatch = useDispatch();
	const [country, setCountry] = useState<string>('');
	const countriesList = useSelector(getAllCountries);
	const params = useParams();

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent, verify?: boolean) => {
		const target = event.currentTarget as HTMLButtonElement;
		(isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
			dispatch(
				putUpgradeRequest({
					user_id: +target.id,
					verify: !verify,
				}),
			);
		setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{members?.map((member) => (
				<div key={member.id} className="tr">
					<div className="td td-break">
						<p className="td-hidden-name"> Photo</p>
						<div className="avatar-wrapper">
							<div className="avatar avatar--s-size">
								{member?.photo_access_hash &&
								String(member?.photo_access_hash) !== String(NUMDEF) ? (
									<Avatar hash={String(member.photo_access_hash)} />
								) : (
									<div className="avatar-custom">
										<span>{createAvataar(member.username)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> Name </p>
						<p className="tooltip-custom">
							<Link
								to={`${ELinksName.USER_DETAILS}/${member.id}/${
									params.tab || 'all-channels'
								}/${String(ETabsUsersDetails.USER_DATA)}`}
								className={`link link--type2`}
							>
								<Highlighter
									highlightClassName="YourHighlightClass"
									searchWords={[search]}
									autoEscape={true}
									textToHighlight={`${member.name.slice(0, 20)}${
										member.name.length > 19 ? '...' : ''
									}`}
								/>
								{/* {member.name.length > 19 ? '...' : ''} */}
							</Link>
							{member.name.length > 19 ? <span className="tooltiptext">{member.name}</span> : null}
						</p>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> User name </p>
						<p className="tooltip-custom">
							<Link
								to={`${ELinksName.USER_DETAILS}/${member.id}/${
									params.tab || 'all-channels'
								}/${String(ETabsUsersDetails.USER_DATA)}`}
								className={`link link--type2`}
							>
								<Highlighter
									highlightClassName="YourHighlightClass"
									searchWords={[search]}
									autoEscape={true}
									textToHighlight={`@${member.username.slice(0, 20)} ${
										member.email.length > 19 ? '...' : ''
									}`}
								/>
								{/* {member.email.length > 19 ? '...' : ''} */}
							</Link>
							{member.username.length > 19 ? (
								<span className="tooltiptext">{`@${member.username}`}</span>
							) : null}
						</p>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> Email </p>
						<p className="tooltip-custom">
							<Link
								to={`${ELinksName.USER_DETAILS}/${member.id}/${
									params.tab || 'all-channels'
								}/${String(ETabsUsersDetails.USER_DATA)}`}
								className={`link link--type2`}
							>
								<Highlighter
									highlightClassName="YourHighlightClass"
									searchWords={[search]}
									autoEscape={true}
									textToHighlight={`${member.email.slice(0, 25)}${
										member.email.length > 24 ? '...' : ''
									}`}
								/>
								{}
							</Link>{' '}
							{member.email.length > 24 ? (
								<span className="tooltiptext">{member.email}</span>
							) : null}
						</p>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name">Registration date</p>
						<div>{moment(member.register_at).format('MMM DD, YYYY')}</div>{' '}
						<div className="gray">{moment(member.register_at).format('h:mm A')}</div>
					</div>
					<div className="td td-break td--right">
						<p className="td-hidden-name"> Last login</p>
						<div>{moment(member.last_login).format('MMM DD, YYYY')}</div>{' '}
						<div className="gray">{moment(member.last_login).format('h:mm A')}</div>
					</div>
					<div className="td td--right-custom">
						<p className="td-hidden-name"> Wallet balance</p>
						<p>
							<Link
								to={`${ELinksName.USER_DETAILS}/${member.id}/${
									params.tab || 'all-channels'
								}/${String(ETabsUsersDetails.FINANCE)}`}
								className="link link--type2"
							>
								{+member.wallet_balance ? numeralFunc(member.wallet_balance) : '-'}
							</Link>
						</p>
					</div>

					<div
						//@ts-ignore
						style={{ textAlign: 'end' }}
						className="td td-break td--right-custom"
					>
						<p className="td-hidden-name"> Register Country</p>
						<p>
							{member?.country_code.length < 4
								? findCountry(countriesList, member.country_code)
								: '-'}
						</p>
					</div>
					<div className="td td-break td--right-custom">
						<p className="td-hidden-name"> Profile</p>
						<p>{member.account_type || '-----'}</p>
					</div>
					<div className="td td--right td-break td--right-custom">
						<p className="td-hidden-name">Gender</p> <p>{member.gender || ''}</p>
					</div>
					<div className="td td--center td-break td--right-custom td--left td--right-custom">
						<p className="td-hidden-name">Verified account</p>
						{onChangeVerification === member.id ? (
							<div className="vertified-butons">
								<button
									id={String(member.id)}
									onClick={(e) => upgradeChannel(e, false)}
									name={EChannelUpgradeType.VERIFY}
									type="button"
									className="vertified-btn"
								>
									<svg
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.625 8.625L6.99529 12.75L15.75 4.5"
											stroke="#27AE60"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
								<button
									name={EChannelUpgradeType.UNVERIFY}
									id={String(member.id)}
									onClick={(e) => upgradeChannel(e, true)}
									type="button"
									className="vertified-btn"
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19.0527 19.9471C19.248 20.1424 19.5646 20.1424 19.7598 19.9471C19.9551 19.7519 19.9551 19.4353 19.7598 19.24L19.0527 19.9471ZM5.3213 4.8015C5.12604 4.60624 4.80946 4.60624 4.61419 4.8015C4.41893 4.99676 4.41893 5.31335 4.61419 5.50861L5.3213 4.8015ZM4.61419 19.24C4.41893 19.4353 4.41893 19.7519 4.6142 19.9471C4.80946 20.1424 5.12604 20.1424 5.3213 19.9471L4.61419 19.24ZM19.7598 5.50861C19.9551 5.31335 19.9551 4.99676 19.7598 4.8015C19.5646 4.60624 19.248 4.60624 19.0527 4.8015L19.7598 5.50861ZM19.7598 19.24L5.3213 4.8015L4.61419 5.50861L19.0527 19.9471L19.7598 19.24ZM5.3213 19.9471L19.7598 5.50861L19.0527 4.8015L4.61419 19.24L5.3213 19.9471Z"
											fill="#EF4062"
										/>
									</svg>
								</button>
							</div>
						) : member.verified ? (
							<button
								onClick={() => setOnChangeVerification(member.id)}
								type="button"
								className="vertified-btn"
							>
								<SvgVerifiedAccount />
							</button>
						) : (
							<button
								onClick={() => setOnChangeVerification(member.id)}
								type="button"
								className="vertified-btn"
							>
								<NotVerifiedGraySvg />
							</button>
						)}
					</div>
				</div>
			))}
		</div>
	);
};
