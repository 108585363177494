import { ArrowBackSvg } from 'assets/svg-icon';
import { EditAIChatBotTabs } from 'components/AIChatBotComponents/EditAIChatBotTabs/EditAIChatBotTabs';
import { Main } from 'components/AIChatBotComponents/Main/Main';
import { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EEditAiChatBotTabs } from 'services/constants/lists-table';
import { Appearance } from 'components/AIChatBotComponents/Appearance/Appearance';
import { TextTemplate } from 'components/AIChatBotComponents/TextTemplate/TextTemplate';
import { ImageTemplate } from 'components/AIChatBotComponents/ImageTemplate/ImageTemplate';
import { Structure } from 'components/AIChatBotComponents/Structure/Structure';
import './EditAiChatBot.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	editBotRequest,
	editButtonsRequest,
	editCommandRequest,
	getBotDataRequest,
	getBotInfoRequest,
	getBotDataSuccess,
	getBotСategoryRequest,
	getTemplateTextRequest,
	getBotStructureInfoRequest,
	getTemplatePhotoRequest,
	deleteBotRequest,
	getCommandsInfoRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';

import {
	getBotDataSelector,
	getCategoryBotSelector,
	getCommandsInfoSelector,
	getButtonsSelector,
	getTemplateTextSelector,
	getBotStructureInfoSelector,
	getTemplatePhotoSelector,
} from 'redux/reducers/ai-chat-bot/selectors';
import { Loader } from 'components/Loader';
import { IButtons, ICommand, IEditBot } from 'redux/reducers/ai-chat-bot/types';
import { isChanged } from 'untils/isChangedObject';
import { validateUsername } from 'untils/validUsernamePattern ';
import { notificationContainer } from 'untils/notificationContainer';

export interface IParams {
	[key: string]: string | any;
}

export interface IEditVerifidOrRecommend {
	recommended?: boolean;
	verified?: boolean;
}

export interface IEditBotInputs {
	nmae?: boolean;
	username?: boolean;
	description?: boolean;
	category?: boolean;
}

export const EditAiChatBot: FC = () => {
	const [modal, setModal] = useState(false);
	const [name, setName] = useState<string>('');
	const [userName, setUserName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [pandaDescription, setPandaDescription] = useState<string>('');
	const [category, setCategory] = useState<string>('');
	const params = useParams<IParams>();
	const categoryList = useSelector(getCategoryBotSelector);
	const buttons = useSelector(getButtonsSelector);
	const commands = useSelector(getCommandsInfoSelector);
	const structures = useSelector(getBotStructureInfoSelector);
	const listTemplateText = useSelector(getTemplateTextSelector);
	const listTemplatePhoto = useSelector(getTemplatePhotoSelector);
	const botInfo = useSelector(getBotDataSelector);
	const [error, setError] = useState('');

	const setDefaultTextInput = () => {
		setUserName(botInfo?.username || '');
		setDescription(botInfo?.description || '');
		setPandaDescription(botInfo?.panda_description || '');
		setName(botInfo?.name || '');
	};

	const dispatch = useDispatch();

	const onModal = () => {
		setModal(!modal);
	};

	const removeAiBot = () => {
		dispatch(deleteBotRequest(params.id));
	};

	// const editTabs = () => {

	// }

	const onEditVerifidOrRecommend = (value: IEditVerifidOrRecommend) => {
		botInfo && dispatch(editBotRequest({ ...botInfo, ...value }));
	};

	const onChangeInput = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;

		switch (name) {
			case 'name':
				validateUsername(setError, value, 'Name');
				setName(value);
				break;

			case 'username':
				validateUsername(setError, value, 'Username');
				setUserName(value);
				break;

			case 'description':
				if (value.length > 128) break;
				setDescription(value);
				break;

			case 'panda_description':
				if (value.length > 210) break;

				setPandaDescription(value);
				break;
			default:
		}
	};

	const onSelect = (value: string) => {
		setCategory(value);
		botInfo && dispatch(editBotRequest({ ...botInfo, category: value }));
	};

	const onEditBotInputs = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { code } = event;

		const data = {
			...botInfo,
			name: name || botInfo?.name,
			username: userName || botInfo?.username,
			description: description || botInfo?.description,
			panda_description: pandaDescription || botInfo?.panda_description,
			category: category || botInfo?.category,
		} as IEditBot;

		if (error && code === 'Enter') notificationContainer(error, 'error');

		code === 'Enter' &&
			!error &&
			botInfo &&
			isChanged(botInfo, data) &&
			dispatch(editBotRequest(data));
	};

	const onEditButton = (
		name: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => {
		const { code } = event;

		if (buttons && code === 'Enter') {
			const buttonsNew: Array<{
				buttons: Array<IButtons>;
				key: string;
				title: string;
			}> = buttons.buttons.map((button) => {
				if (button.key === key) {
					const result = button.buttons.map((btn) => {
						if (btn.option === option) {
							return { ...btn, name: name[String(option)] };
						}
						return btn;
					});
					return { ...button, buttons: result };
				}
				return button;
			});

			code === 'Enter' && dispatch(editButtonsRequest({ ...buttons, buttons: buttonsNew }));
		}
	};

	const onEditCommand = (
		command: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => {
		const { code } = event;

		if (commands && code === 'Enter') {
			const commandsNew: Array<ICommand> = commands.commands.map((item) => {
				if (item.key === key) {
					return { ...item, command: command[String(option)] };
				}
				return item;
			});

			dispatch(editCommandRequest({ ...commands, commands: commandsNew }));
		}
	};

	const onSave = useCallback(() => {
		const data = {
			...botInfo,
			name: name || botInfo?.name,
			username: userName || botInfo?.username,
			description: description || botInfo?.description,
			panda_description: pandaDescription || botInfo?.panda_description,
			category: category || botInfo?.category,
		} as IEditBot;

		// Виклик dispatch тільки якщо дані змінилися
		if (botInfo && isChanged(botInfo, data)) {
			dispatch(editBotRequest(data));
		}
	}, [
		botInfo,
		name,
		userName,
		pandaDescription,
		description,
		category,
		isChanged,
		dispatch,
		editBotRequest,
	]);

	const definePage = () => {
		switch (params.tab) {
			case EEditAiChatBotTabs.STRUCTURE:
				return (
					<Structure
						onEditCommand={onEditCommand}
						onEditButton={onEditButton}
						commands={commands}
						buttons={buttons}
						structures={structures}
					/>
				);
			case EEditAiChatBotTabs.APPEARANCE:
				return <Appearance botId={params.id} />;
			case EEditAiChatBotTabs.TEXT_TEMPLATE:
				return listTemplateText ? (
					<TextTemplate params={params} templates={listTemplateText} />
				) : null;
			case EEditAiChatBotTabs.IMAGE_TEMPLATE:
				return listTemplatePhoto ? (
					<ImageTemplate params={params} templates={listTemplatePhoto} />
				) : null;
			default:
				return botInfo ? (
					<Main
						onEditVerifidOrRecommend={onEditVerifidOrRecommend}
						onChangeInput={onChangeInput}
						onEditBotInputs={onEditBotInputs}
						onSave={onSave}
						onSelect={onSelect}
						onModal={onModal}
						categoryList={categoryList}
						botInfo={botInfo}
						description={description}
						pandaDescription={pandaDescription}
						name={name}
						userName={userName}
						setDefaultTextInput={setDefaultTextInput}
					/>
				) : null;
		}
	};

	useEffect(() => {
		dispatch(getBotInfoRequest(params.id || ''));
		dispatch(getBotDataRequest(params.id || ''));
		dispatch(getBotСategoryRequest(params.id || ''));
		dispatch(getCommandsInfoRequest(params.id || ''));

		return () => {
			dispatch(getBotDataSuccess(null));
		};
	}, []);

	useEffect(() => {
		params.tab === 'text-template' && dispatch(getTemplateTextRequest(params.id || ''));
		params.tab === 'image-template' && dispatch(getTemplatePhotoRequest(params.id || ''));
		params.tab === 'structure' && dispatch(getBotStructureInfoRequest(params.id || ''));
	}, [params]);

	return (
		<div className="main-content">
			{modal && (
				<PopUpWrapper
					// value={String(wordId)}
					handleButton={removeAiBot}
					isOpenModal={onModal}
					title="Are you sure you want to delete this AI Bot?"
					btnName="Yes, I’m sure"
					disablet={'true'}
				/>
			)}
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap">
				<div className="title-block">
					<Link className="back-button" to={ELinksName.AI_CHAT_BOT}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">{params?.name.replaceAll('_', ' ')}</p>
				</div>
			</div>
			<EditAIChatBotTabs params={params} shows={commands?.shows} />
			<div className="table-block">
				<div className="edit-bot">{botInfo || buttons ? definePage() : <Loader />}</div>
			</div>
		</div>
	);
};
