import { FC, useEffect, useRef, useState } from 'react';
import { WEB_API_URL, WEB_API_URL_STAGE } from 'services/constants/env';
import { displayProtectedImage } from 'untils/getFile';
import doc from '../../assets/images/document.png';
import { SmallLoader } from 'ui/SmallLoader';
import { onHttp } from 'untils/onHttp';
import { AiChatSvg, DocxSvg, PlayVideoSvg, SoundSvg } from 'assets/svg-icon';
import { useLocation } from 'react-router-dom';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	hash: string;
	endpoint?: string;
	type?: string;
}

export const Avatar: FC<IProps> = ({ hash, type, endpoint = '/storage/photo' }) => {
	const refImg = useRef<any>();
	const [loader, setLoader] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		Number(hash) &&
			refImg &&
			displayProtectedImage(
				refImg,
				`${onHttp()}${endpoint}/${hash}`,
				localStorage.getItem('token') || '',
				setLoader,
			);
	}, [refImg, hash]);

	return (
		<>
			{type?.includes('video') ? (
				pathname.includes('support') ? (
					<a download href="">
						<PlayVideoSvg />
					</a>
				) : (
					<PlayVideoSvg />
				)
			) : (
				<>
					{loader ? <SmallLoader /> : null}
					<img style={{ display: loader ? 'none' : 'block' }} ref={refImg} alt="avatar" />
				</>
			)}
		</>
	);
};
