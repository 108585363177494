import { recommended, verified } from 'CONST/cardName';
import { ChangeEvent, FC, KeyboardEvent, useEffect } from 'react';
import { Select } from 'ui/Select/Select';
import './Main.scss';
import { DeleteSvg } from 'assets/svg-icon';
import { SelectList } from 'ui/SelectList/SelectList';
import { IBot, ICategory } from 'redux/reducers/ai-chat-bot/types';
import { useParams } from 'react-router-dom';
import { IEditVerifidOrRecommend } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { Textarea } from 'ui/Textarea/Textarea';
import { WatchButton } from 'ui/WatchButton/WatchButton';

interface IProps {
	onModal: () => void;
	categoryList: Array<ICategory> | null;
	botInfo: null | IBot;
	onChangeInput: (event: ChangeEvent) => void;
	onEditBotInputs: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSelect: (value: string) => void;
	onEditVerifidOrRecommend: (value: IEditVerifidOrRecommend) => void;
	name: string;
	userName: string;
	description: string;
	pandaDescription: string;
	setDefaultTextInput: () => void;
	onSave: () => void;
}

export interface IParams {
	[key: string]: string | any;
}

export const Main: FC<IProps> = ({
	onChangeInput,
	onEditBotInputs,
	onModal,
	onEditVerifidOrRecommend,
	setDefaultTextInput,
	onSelect,
	categoryList,
	botInfo,
	name,
	userName,
	description,
	pandaDescription,
	onSave,
}) => {
	const params = useParams<IParams>();

	const changeRecommended = (channel_id: number, recommend: boolean) => {
		onEditVerifidOrRecommend({ recommended: recommend });
	};

	const changeVerified = (channel_id: number, verify: boolean) => {
		onEditVerifidOrRecommend({ verified: verify });
	};

	useEffect(() => {
		setDefaultTextInput();
	}, []);

	return (
		<div className="edit-main">
			<div className="edit-main__header">
				<div className="edit-main__select">
					<Select data={verified} defaultType={botInfo?.verified} upgradeChannel={changeVerified} />

					<Select
						data={recommended}
						defaultType={botInfo?.recommended}
						upgradeChannel={changeRecommended}
					/>
				</div>
				<button onClick={onModal} type="button" className="edit-main__btn-remove">
					<DeleteSvg />
					<span>Delete Ai Bot</span>
				</button>
			</div>
			<div className="edit-main__body">
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Name</div>
					<input
						title="Press Enter to make changes"
						name="name"
						value={name}
						placeholder="Enter name"
						type="text"
						className="edit-main__input"
						onChange={onChangeInput}
						onKeyDown={onEditBotInputs}
					/>
				</div>
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Username</div>
					<input
						title="Press Enter to make changes"
						name="username"
						value={userName}
						placeholder="Enter username"
						type="text"
						className="edit-main__input"
						onChange={onChangeInput}
						onKeyDown={onEditBotInputs}
					/>
				</div>
				<div>
					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div className="edit-main__label">Description</div>
						<Textarea
							name="description"
							value={description}
							placeholder="Enter description"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{128 - description.length}</div>
					</div>
					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div className="edit-main__label">Panda Description</div>
						<Textarea
							name="panda_description"
							value={pandaDescription}
							placeholder="Enter description"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{210 - pandaDescription.length}</div>
					</div>
				</div>

				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Category</div>
					<SelectList
						defaultSelect={botInfo?.category}
						onSelect={onSelect}
						placeholder="Enter category"
						data={categoryList?.map((item) => item.category) || ['Empty']}
					/>
				</div>
			</div>
			{description || pandaDescription ? (
				<WatchButton onSave={onSave} vars={[description, pandaDescription, userName, name]} />
			) : null}
		</div>
	);
};
