import { ArrowDownGraySvg, ArrowUpGraySvg, EditSmallSvg } from 'assets/svg-icon';
import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editBotStructureInfoRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { IStructureList, IStructureRsponse } from 'redux/reducers/ai-chat-bot/types';
import { Input } from 'ui/Input';
import { Textarea } from 'ui/Textarea/Textarea';
import './StructureList.scss';
import { WatchButton } from 'ui/WatchButton/WatchButton';

interface IPropsStructureList {
	structures: IStructureRsponse;
	structure: IStructureList;
	keyStructure: string;
	handleButton: (event: MouseEvent) => void;
}

export const StructureList: FC<IPropsStructureList> = ({
	handleButton,
	structures,
	keyStructure,
	structure,
}) => {
	const [description, setDescription] = useState(structure.text);
	const [title, setTitle] = useState(structure.title);
	const dispatch = useDispatch();

	const onChange = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;

		name === 'description' ? setDescription(value) : setTitle(value);
	};

	const onEditTemplate = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { code } = event;

		const data =
			{
				bot_id: structures?.bot_id as number,
				structure: structures?.structure?.map((item) => {
					if (item.key === keyStructure) {
						return {
							...item,
							text: description,
							title,
						};
					}

					return item;
				}),
			} || [];

		code === 'Enter' && dispatch(editBotStructureInfoRequest(data as IStructureRsponse));
	};

	const onSave = () => {
		const data =
			{
				bot_id: structures?.bot_id as number,
				structure: structures?.structure?.map((item) => {
					if (item.key === keyStructure) {
						return {
							...item,
							text: description,
							title,
						};
					}

					return item;
				}),
			} || [];

		dispatch(editBotStructureInfoRequest(data as IStructureRsponse));
	};

	return (
		<div key={structure.key} className="structure__box">
			<div id={structure.key} onClick={handleButton} className="structure__header">
				<div className="structure__name">
					<div className="structure__line">
						<span></span>
						<span></span>
					</div>
					<span>{structure.title}</span>
					<EditSmallSvg />
				</div>
				<div className="structure__arrow">
					{keyStructure === String(structure.key) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
				</div>
			</div>
			{keyStructure === String(structure.key) ? (
				<div className="accordion accordion-input">
					{/* <div className="accordion__box accordion__box-2">
						<div className="accordion__left-content">Title</div>
						<label className="accordion__label">
							<Input
								name={'title'}
								type={'text'}
								handleInput={onChange}
								onKeyDownInput={onEditTemplate}
								value={title}
								placeholder={'Title'}
							/>
						</label>
					</div> */}
					<div className="accordion__box accordion__box-2">
						<div className="accordion__left-content">Text</div>
						<label className="accordion__label">
							<Textarea
								handleTextarea={onChange}
								onKeyDownInput={onEditTemplate}
								name={'description'}
								placeholder={'Description'}
								value={description}
							/>
						</label>
					</div>
					{description ? <WatchButton onSave={onSave} vars={[description, title]} /> : null}
				</div>
			) : null}
		</div>
	);
};
