import classNames from 'classnames';
import { IParams } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IShows } from 'redux/reducers/ai-chat-bot/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EEditAiChatBotTabs } from 'services/constants/lists-table';

export const EditAIChatBotTabs: FC<{ params: IParams; shows: IShows | undefined }> = ({
	params,
	shows,
}) => {
	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.MAIN}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.MAIN || !params.tab,
				})}
			>
				Main
			</Link>
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.STRUCTURE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.STRUCTURE,
				})}
			>
				Structure
			</Link>
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.APPEARANCE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.APPEARANCE,
				})}
			>
				Appearance
			</Link>{' '}
			{!shows?.document_bot_config_bot_command ? (
				<>
					{shows?.text_bot_config_bot_command && (
						<Link
							to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.TEXT_TEMPLATE}`}
							className={classNames('tabs-buttons-nav-link', {
								active: params.tab === EEditAiChatBotTabs.TEXT_TEMPLATE,
							})}
						>
							Text template
						</Link>
					)}
					{((shows?.text_bot_config_bot_command && shows?.photo_bot_config_bot_command) ||
						shows?.photo_bot_config_bot_command) &&
					!shows?.edit_bot_config_bot_command ? (
						<Link
							to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.IMAGE_TEMPLATE}`}
							className={classNames('tabs-buttons-nav-link', {
								active: params.tab === EEditAiChatBotTabs.IMAGE_TEMPLATE,
							})}
						>
							Image templates
						</Link>
					) : null}
				</>
			) : null}
		</div>
	);
};
