import React, { useState, useEffect, useRef, memo } from 'react';

// Хук для збереження попереднього значення
function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();

	useEffect(() => {
		// Оновлюємо попереднє значення після кожного рендеру
		ref.current = value;
	}, [value]);

	return ref.current;
}

// Хук для перевірки змін та встановлення стану hasChanged
function useCheckChanges<T>(value: T) {
	const [hasChanged, setHasChanged] = useState(false);
	const prevValue = usePrevious(value);
	const isFirstRender = useRef(true); // Додаємо ref для відстеження першого рендеру

	useEffect(() => {
		if (isFirstRender.current) {
			// Пропускаємо перевірку при першому рендері
			isFirstRender.current = false;
			return;
		}

		if (prevValue !== value) {
			// Значення змінилося
			setHasChanged(true);
		} else {
			// Значення не змінилося
			setHasChanged(false);
		}
	}, [value]);

	return hasChanged;
}

interface IProps {
	onSave: () => void;
	vars: Array<string>;
}

// Компонент WatchButton
// eslint-disable-next-line react/display-name
export const WatchButton: React.FC<IProps> = memo(({ onSave, vars }) => {
	// Використовуємо хук для перевірки змін у значенні пропсу description
	const hasChanged = useCheckChanges(vars);

	return (
		<div>
			{/* Кнопка стає активною тільки при зміні description */}
			<button disabled={!hasChanged} onClick={onSave} className="save-button">
				Save
			</button>
		</div>
	);
});
