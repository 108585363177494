import { FC, MouseEvent, useState } from 'react';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { ArrowDownGraySvg, ArrowUpGraySvg, PlusBlueSvg } from 'assets/svg-icon';
import { Button } from 'ui/Button/Button';
import './TextTemplate.scss';
import { Accordion } from './Accordion/Accordion';
import { IResponseTemplateText } from 'redux/reducers/ai-chat-bot/types';
import { IParams } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { useNavigate } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

interface ITextTemplateProps {
	templates: IResponseTemplateText;
	params: IParams;
}

export const TextTemplate: FC<ITextTemplateProps> = ({ templates, params }) => {
	const [open, setOpen] = useState<string>('');
	const navigation = useNavigate();

	const handleButton = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;
		setOpen(id === open ? '' : id);
	};

	const createTemplate = () => {
		navigation(`/${ELinksName.CREATE_TEXT_TEMPLATE}/${params.id}`, {
			replace: true,
		});
	};

	return (
		<div className="structure">
			{templates?.templates?.map((structure, index) => (
				<div key={structure.option} className="structure__box">
					<div id={String(structure.option)} onClick={handleButton} className="structure__header">
						<div className="structure__name">
							<span className="structure__line">{`${structure.option})`}</span>
							<span>{firstUppercaseLetter(structure.name.replaceAll('_', ' '))}</span>
						</div>
						<div className="structure__arrow">
							{open === String(structure.option) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
						</div>
					</div>
					{open === String(structure.option) ? (
						<Accordion templates={templates} structure={structure} text={'text'} />
					) : null}
				</div>
			))}
			<div className="template-btn">
				<Button
					className="button-border"
					onClick={createTemplate}
					type={'button'}
					name={'Add New Template'}
				>
					<PlusBlueSvg />
				</Button>
			</div>
		</div>
	);
};
