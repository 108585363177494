import { AiChatSvg, HorizenPointsSvg, NotVerifiedGraySvg } from 'assets/svg-icon';
import { FC } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { IBotPromoCodeResonse } from 'redux/reducers/ai-chat-bot/types';
import { Avatar } from 'ui/Avatar/Avatar';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	code: IBotPromoCodeResonse;
}

export const ListCodeBody: FC<IProps> = ({ code }) => {
	return (
		<div className="tr">
			<Link to={`${ELinksName.CODES_INFO}/${code.code_id}/${code.label}`} className="td">
				<p className="td-hidden-name">Name</p>
				<p>{code.label}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Avatar</p>
				<div className="avatar-wrapper">
					<div className="avatar avatar--circle">
						{code.photo_access_hash && code.photo_access_hash !== String(NUMDEF) ? (
							<Avatar hash={code.photo_access_hash} />
						) : (
							<AiChatSvg />
						)}
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name"> Bot Name </p>
				<p>{code.bot_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>{moment(code.date_create).format('MMM DD, YYYY')}</p>
				<p className="gray">{moment(code.date_create).format('hh:mm A')}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Codes number</p>
				<p className="upper-text-custom">{code.count_codes}</p>
			</div>
			<div className="td td--right td--right-custom td--left">
				<p className="td-hidden-name">Codes life</p>
				<p>{code.life_time_days}</p>
			</div>
		</div>
	);
};
