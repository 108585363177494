import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IChangeStatus,
	IChangeStatusResponse,
	IChannelsResponse,
	IContent,
	ICreateLetterRequest,
	IMassage,
	IMassages,
	IMassagesResponse,
	INote,
	INoteRequest,
	ISupportsInfo,
	ISupportState,
	ITicketsRequest,
	ITicketsResponse,
	ITypes,
	IUploadFileRequred,
	IUsers,
	IUsersRequest,
} from './types';
import { onJsonParseBigin } from 'untils/jsonParse';

export const initialState: ISupportState = {
	users: null,
	types: null,
	massages: null,
	chatList: null,
	subscribeAll: null,
	notes: [
		{
			body: '',
			epoch: '',
			id: null,
			offset: null,
			reply_id: null,
			support_id: null,
			updated_at: '',
			user_id: null,
			ut: '',
			created_at: '',
			name: '',
			text: {
				String: '',
			},
		},
	],
	client: '',
	reportsLoader: false,
	tickets: null,
	search: '',
};

const support = createSlice({
	name: '@@support',
	initialState,
	reducers: {
		sendLetterRequest: (state, action: PayloadAction<ICreateLetterRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		getLetterSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = false;
		},
		getUsersRequest: (state, action: PayloadAction<IUsersRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		getUsersSuccess: (state, action: PayloadAction<IUsers>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.users = payload;
		},

		subscribeAllRequest: (state) => {},
		subscribeAllSuccess: (state, action: PayloadAction<ISupportsInfo>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.subscribeAll = payload;
		},
		subscribeRequest: (state) => {},
		subscribeSuccess: (state, action: PayloadAction<ISupportsInfo>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.subscribeAll = payload;
		},
		getTypesRequest: (state) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		getTypesResponse: (state, action: PayloadAction<Array<ITypes>>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.types = payload;
		},

		getMassageRequest: (state, action: PayloadAction<IMassages>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		getMassageResponse: (state, action: PayloadAction<IMassagesResponse>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.massages = payload;

			const chats: Array<IContent> = payload.messages.map((chat) => {
				const obj = onJsonParseBigin(chat.body);

				return obj.admin_id ? obj : onJsonParseBigin(obj.input);
			});

			getReportsState.chatList = chats.map((chat) => {
				if (chat.input || chat.document) {
					return {
						admin: true,
						message: chat.input,
						hash: String(chat?.document?.access_hash).replace(/[^+\d]/g, '') || '',
						type: chat?.document?.predicate_name,
						mime_type: chat?.document?.mime_type,
					};
				} else {
					return {
						admin: false,
						message: chat?.outMessage?.message || chat.input,
						hash:
							String(chat?.media?.document?.access_hash).replace(/[^+-\d]/g, '') ||
							String(chat?.media?.photo_FLAGPHOTO?.access_hash).replace(/[^+-\d]/g, ''),
						type:
							chat?.media?.document?.predicate_name || chat?.media?.photo_FLAGPHOTO.predicate_name,
						mime_type: chat?.media?.document?.mime_type,
					};
				}
			});
		},

		removeListChats: (state) => {
			const getReportsState = state;
			getReportsState.tickets = null;
		},

		changeStatusRequest: (state, action: PayloadAction<IChangeStatus>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		changeStatusResponse: (state, action: PayloadAction<IChangeStatus>) => {
			const getReportsState = state;
			const { payload } = action;

			if (getReportsState.users) {
				getReportsState.users = {
					data: getReportsState.users.data.map((user) => {
						if (payload.id === user.support_id) {
							return {
								...user,
								status: {
									...user.status,
									Int64: payload.type as number,
								},
							};
						}
						return user;
					}),
					total: getReportsState.users.total,
				};
			}
			getReportsState.reportsLoader = false;
		},

		changeTypeRequest: (state, action: PayloadAction<IChangeStatus>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		changeTypeResponse: (state, action: PayloadAction<IChangeStatus>) => {
			const getReportsState = state;
			const { id, type } = action.payload;
			getReportsState.reportsLoader = false;
			const asd = { ...getReportsState.tickets?.data };
			if (getReportsState.tickets?.data) {
				getReportsState.tickets.data.forEach((ticket) => {
					if (ticket.support_id === id) {
						getReportsState.users &&
							getReportsState.users.data.forEach((user) => {
								if (user.user_id === ticket.user_id) {
									user.type_id.Int64 = type as number;
									user.type_name.String = getReportsState.types?.find((el) => el.Id === type)
										?.Title as string;
								}
							});
						ticket.type_id.Int64 = type as number;
						ticket.type_name.String = getReportsState.types?.find((el) => el.Id === type)
							?.Title as string;
					}
				});
			}
		},

		getNotesRequest: (state, action: PayloadAction<string>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		getNotesResponse: (state, action: PayloadAction<Array<INote>>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.notes = payload;
		},

		creareNoteRequest: (state, action: PayloadAction<INoteRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		createNoteResponse: (state, action: PayloadAction<INote>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			if (getReportsState.notes) {
				getReportsState.notes?.push(payload);
			} else {
				getReportsState.notes = [payload];
			}
		},

		editNoteRequest: (state, action: PayloadAction<INoteRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		editNoteResponse: (state, action: PayloadAction<INote>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.notes?.forEach((note) => {
				if (note.id === payload.id) note.text.String = payload.text.String;
			});
		},

		deleteNoteRequest: (state, action: PayloadAction<string>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		deleteNoteResponse: (state, action: PayloadAction<number>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			if (getReportsState.notes)
				getReportsState.notes = getReportsState.notes?.filter((note) => note.id !== payload);
		},

		onConect: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.client = payload;
		},

		ticketsRequest: (state, action: PayloadAction<ITicketsRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		ticketsResponse: (state, action: PayloadAction<ITicketsResponse | null>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.tickets = payload;
		},

		uploadFileRequest: (state, action: PayloadAction<IUploadFileRequred>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},
		uploadFileResponse: (state, action: PayloadAction<any>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.tickets = payload;
		},

		setMassage: (
			state,
			action: PayloadAction<{
				admin: boolean;
				message: string;
				hash?: string;
				type?: string;
				mime_type?: string;
			}>,
		) => {
			const getReportsState = state;
			const { payload } = action;

			getReportsState.chatList
				? getReportsState.chatList.push(payload)
				: (getReportsState.chatList = [payload]);
		},

		setTicket: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.chatList?.push({ admin: true, message: payload, hash: '' });
		},

		changeStatus: (state, action: PayloadAction<{ type: number; id: number }>) => {
			const getReportsState = state;
			const { id, type } = action.payload;
			getReportsState.tickets?.data.forEach((ticket) => {
				if (ticket.support_id === id) ticket.status.Int64 = type;
			});
		},

		resetMassage: (state) => {
			const getReportsState = state;
			getReportsState.chatList = null;
		},

		searchTickets: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.search = payload;
		},

		reportsInitState: (state) => {
			const reportsInitState = state;
			reportsInitState.reportsLoader = false;
		},
	},
});

export default support.reducer;
export const {
	sendLetterRequest,
	getLetterSuccess,
	getUsersRequest,
	getUsersSuccess,
	subscribeAllRequest,
	subscribeAllSuccess,
	subscribeRequest,
	subscribeSuccess,
	getTypesRequest,
	getTypesResponse,
	getMassageRequest,
	getMassageResponse,
	changeStatusRequest,
	changeStatusResponse,
	changeTypeRequest,
	changeTypeResponse,
	getNotesRequest,
	getNotesResponse,
	creareNoteRequest,
	createNoteResponse,
	editNoteRequest,
	editNoteResponse,
	deleteNoteRequest,
	deleteNoteResponse,
	onConect,
	ticketsRequest,
	ticketsResponse,
	changeStatus,
	resetMassage,
	setMassage,
	searchTickets,
	uploadFileRequest,
	uploadFileResponse,
	setTicket,
	reportsInitState,
	removeListChats,
} = support.actions;
