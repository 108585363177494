import { BoltSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { ITypes, IUsers } from 'redux/reducers/support/types';
import moment from 'moment';
import { GET_PHOTO } from 'services/constants/env';
import { createAvataar } from 'untils/createAvatar';
import { Avatar } from 'ui/Avatar/Avatar';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	onOpenUser: (value: number, status: number, user_id: number) => void;
	activUser: number;
	users: null | IUsers;
	types: null | Array<ITypes>;
	offset: number;
	setOffset: (value: (prev: number) => number) => void;
	scrollRef: any;
}

export const ListUsers: FC<IProps> = ({
	onOpenUser,
	activUser,
	users,
	scrollRef,
	types,
	offset,
	setOffset,
}) => {
	const onStatus = (status: number) => {
		switch (status) {
			case 0:
				return 'New';

			case 1:
				return 'In Progress';

			case 2:
				return 'Completed';
		}
	};

	return (
		<div className="sidebar-left__users-wrap users">
			<ul ref={scrollRef} className="users__list">
				{users?.data?.map((user) => (
					<li key={user.support_id} className="users__item">
						<button
							onClick={() => onOpenUser(user?.support_id, user?.status.Int64, user?.user_id)}
							className={classNames('users__button', {
								'button-active': activUser === user?.support_id,
							})}
						>
							<div className="users__ava-wrap">
								{user?.photo?.AccessHash && String(user?.photo?.AccessHash) !== String(NUMDEF) ? (
									<Avatar hash={String(user?.photo?.AccessHash)} />
								) : (
									<div className="avatar-custom">
										<span>
											{createAvataar(user?.user_info.FirstName + ' ' + user?.user_info.LastName)}
										</span>
									</div>
								)}
								<div className="users__info">
									<span className="users__text-bolt">
										{user?.user_info.FirstName + ' ' + user?.user_info.LastName}
									</span>
									<span className="users__text-regular">{user?.type_name?.String}</span>
								</div>
							</div>

							<div className="users__info">
								<span className={`users__status users__status--${user?.status?.Int64}`}>
									<BoltSvg />
									<span>{onStatus(user?.status?.Int64)}</span>
								</span>
								<span className="users__text-regular">
									{moment(user?.created_at).format('MMM DD')}
								</span>
							</div>
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};
