import { PlusBlueSvg } from 'assets/svg-icon';
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {
	editTemplateTextRequest,
	getTemplateTextRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { Button } from 'ui/Button/Button';
import { Input } from 'ui/Input';
import { Textarea } from 'ui/Textarea/Textarea';
import { IParams } from '../EditAiChatBot/EditAiChatBot';
import { getTemplateTextSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { notificationContainer } from 'untils/notificationContainer';

export const AddNewTemplateText: FC = () => {
	const dispatch = useDispatch();
	const params = useParams<IParams>();
	const listTemplateText = useSelector(getTemplateTextSelector);
	const [title, setTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const navigate: NavigateFunction = useNavigate();

	const handleInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;

		name === 'name' ? setTitle(value) : setDescription(value);
	};

	const createTemplate = () => {
		title && description
			? dispatch(
					editTemplateTextRequest({
						navigate,
						data: {
							bot_id: Number(params.id),
							templates: [
								...listTemplateText.templates,
								{
									name: title,
									option: Number(listTemplateText?.templates.length) + 1,
									prompt: description,
								},
							],
						},
					}),
			  )
			: notificationContainer('Field title and description are required', 'info');
	};

	useEffect(() => {
		dispatch(getTemplateTextRequest(params.id || ''));
	}, []);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title big-first-letter">Add New Template Text</p>
				</div>
			</div>

			<div className="table-block table-block-system margin-top">
				<div className="table-wrapper">
					<section className="system">
						<div className="structure">
							<div className="structure__box">
								<div className="accordion">
									<div className={`accordion__box-text`}>
										<div className="accordion__left-content">Title</div>
										<div className="accordion__right-content">
											{/* <Editor styles={{ minHeight: 'auto' }} /> */}
											<Input name={'name'} type={'text'} handleInput={handleInput} value={title} />
										</div>
									</div>
									<div className={`accordion__box-text`}>
										<div className="accordion__left-content">Description</div>
										<div className="accordion__right-content">
											{/* <Editor styles={{ minHeight: 'auto' }} /> */}
											<Textarea
												handleTextarea={handleInput}
												name={'description'}
												value={description}
											/>
										</div>
									</div>
									{/* {structure.code ? (
				<div className="accordion__box">
					<div className="accordion__left-content">{structure.code}</div>
					<div className="accordion__right-content">
						<Editor styles={{ minHeight: 'auto' }} />
					</div>
				</div>
			) : null} */}
								</div>
							</div>

							<div className="template-btn">
								<Button
									className="button-border"
									onClick={createTemplate}
									type={'button'}
									name={'Add New Template'}
								>
									<PlusBlueSvg />
								</Button>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};
